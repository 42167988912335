import {filterEmptyParams} from "~/utils/filters";

export function usePayments() {
    async function createLink(orderId, paymentPercentage) {
        return useHttpPost('/payments/create-link/' + orderId, {
          query: {
              order_id: orderId,
              payment_percentage: paymentPercentage
          }
        })
      }
      
    async function issuingInvoice(orderId, paymentPercentage) {
        return useHttpPost('/payments/issuing-an-invoice-for-payment/' + orderId, {
            query: {
                order_id: orderId,
                payment_percentage: paymentPercentage
            }
        })
    }

    async function paymentFromClientBalance(orderId) {
        return useHttpPost('/payments/payment-from-client-balance/' + orderId, {
            query: {
                order_id: orderId
            }
        })
    }


  return {
      createLink,
      issuingInvoice,
      paymentFromClientBalance
  }
}
