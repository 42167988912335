import {filterEmptyParams} from "~/utils/filters";

export function useDecors() {
    const decors = useState('decors', () => [])
    const decorPage = useState('decorPage', () => 1)
    const decorPages = useState('decorPages', () => 1)

    async function fetchDecors(data = {}) {
        return useHttpGet('decor', {query: filterEmptyParams(data)})
            .then((response) => {
                decors.value = response.data.value?.items ?? []
                decorPages.value = response.data.value?.pages ?? 1
                decorPage.value = 1
                
                return response
            })
    }

    async function loadMore(data = {}) {
        if (decorPage.value >= decorPages.value) {
            return;
        }
        decorPage.value++

        data.page = decorPage.value
        
        return useHttpGet('decor', {query: filterEmptyParams(data)})
            .then((response) => {
                decors.value.push(...response.data.value.items)
                decorPages.value = response.data.value.pages ?? 1
            })
    }

    async function getDecorById(id) {
        return useHttpGet('decor/' + id)
    }

    async function getCountFilter(data = {}) {
        return useHttpGet('decor/count-filter/', {query: filterEmptyParams(data)})
    }

    async function getDecorCountById(id, startDate, endDate) {
        return useHttpGet('/decor/' + id + '/count_from_date/', {
            query: {
                start_date: startDate,
                end_date: endDate
            }
        })
    }

    return {
        decors,
        decorPage,
        decorPages,
        fetchDecors,
        getDecorById,
        loadMore,
        getCountFilter,
        getDecorCountById
    }
}
